<template lang="pug">
q-page(padding)
  .text-h3.text-center.q-mb-xl.q-mt-lg.text-primary {{ $t('terms_privacy_policy') }}


  .row.text-left.q-mt-lg(style="margin-left: 10vw; margin-right: 10vw")
    .col-12
      .text-body1.q-mt-lg.q-mb-md This website is operated by Action Lab, Monash University. The information that you provide and that is processed by us (such as the edited submission) will be disclosed to the Department of Families, Fairness and Housing for the purposes of grant acquittal for the Multicultural Festivals and Events Program. The personal data that you provide is also collected for the following purposes using these services:

      ul
        li Twilio SendGrid (for email notifications): https://www.twilio.com/legal/privacy
        li Google Cloud and Firebase (for management and storage of submissions): https://cloud.google.com/security/privacy & https://firebase.google.com/support/privacy
        li AWS (for report-related data storage and processing): https://aws.amazon.com/compliance/data-privacy/
      .text-body1.q-mt-lg.q-mb-md Beyond these services and beyond disclosing your information to the Department of Families, Fairness and Housing, we will not share any of your information, nor will any of your data be sold to third parties. 

 

      .text-body1.q-mt-lg.q-mb-md Any personal information provided will be handled in accordance with the Privacy and Data Protection Act 2014 (Vic) and applicable privacy laws. Enquiries about access to personal information held by Fairer Victoria(a division of the Department, Families, Fairness and Housing) should be directed to the Privacy team at the Department of Families, Fairness and Housing by emailing privacy@dffh.vic.gov.au.
      .text-body1.q-mt-lg.q-mb-md Information provided to DFFH on this platform will be used to determine your organisations compliance with current funding requirements, to assess the video acquittal and to contact you about your video acquittal. The information provided by you will be stored on DFFH’s Grants Management System and will be accessible by other registered users within your Organisation. Any information provided in this video acquittal will be kept confidential and will not be shared on any public forum without your prior written consent. We will take all necessary measures to ensure the confidentiality and security of your information. We will not disclose any of your information to any third party, except where required by law or where we have obtained your prior written consent. If you have any concerns or questions about the confidentiality and security of your information, please email multicultural-festivals.events@dffh.vic.gov.au.
      .text-body1.q-mt-lg.q-mb-md Information provided to DFFH may be shared with other government departments, ministerial offices, and members of parliament (including key organisation contacts) for the purpose of sending notices or updates on topics of interest such as other funding opportunities, information on community consultations, policy, program, legislative announcements and multicultural information. If you do not wish for your details to be shared with other Victorian government departments, ministerial offices and members of Parliament please email multicultural-festivals.events@dffh.vic.gov.au

      //- .text-body1.q-mt-lg.q-mb-md Information provided to DFFH on this application form will be used to determine your organisation's eligibility for funding, to assess the application and to contact you about your application. The information provided by you will be stored on DFFH's Grants Management System and will be accessible by other registered users within your organisation. If you do not provide DFFH with the information on this application form, your grant application may be affected, and it may impact the likelihood of a successful outcome.

      //- .text-body1.q-mt-lg.q-mb-md If your grant application is successful, your organisation name, event description, amount awarded, and duration of grant will be published on the DFFH website or other Victorian Government website or publication.
  //-     .text-h4.text-primary {{ $t('terms_disclaimer') }}
  //-     ul.q-ml-none.q-pl-md
  //-       li.text-body1(
  //-         v-for="(disclaimer, index) in messages.terms_disclaimer_2",
  //-         :key="index"
  //-       ) {{ disclaimer }}

    //- .col-12.q-mt-lg
    //-   .text-h4.text-primary {{ $t('terms_privacy_policy') }}
    //-   .text-body1.q-mt-lg.q-mb-md(
    //-     v-for="(termItem, index) in messages.terms_privacy",
    //-     :key="index",
    //-     :class="{ 'text-bold': index.endsWith('bold') }"
    //-   ) {{ termItem }}

    //- .col-12.q-mt-lg
    //-   .text-h4.text-primary {{ $t('terms_a_terms_of_use') }}
    //-   .text-body1.q-mt-lg.q-mb-md(
    //-     v-for="(termItem, index) in messages.terms_use",
    //-     :key="index",
    //-     :class="{ 'text-bold': index.endsWith('bold') }"
    //-   ) {{ termItem }}

    //- .col-12.q-mt-lg
    //-   .text-h4.text-primary {{ $t('terms_b_use_of_personal_info') }}
    //-   .text-body1.q-mt-lg.q-mb-md(
    //-     v-for="(termItem, index) in messages.terms_personal",
    //-     :key="index",
    //-     :class="{ 'text-bold': index.endsWith('bold') }"
    //-   ) {{ termItem }}
    //-   ul.q-ml-none.q-pl-md
    //-     li.text-body1(
    //-       v-for="(termItem, index) in messages.terms_b_use_of_personal_info_4_items",
    //-       :key="index"
    //-     ) {{ termItem }}
    //-   .text-body1.q-mt-lg.q-mb-md {{ $t('terms_b_use_of_personal_info_extra') }}

    //- .col-12.q-mt-lg
    //-   .text-h4.text-primary {{ $t('terms_c_non_personal_info') }}
    //-   .text-body1.q-mt-lg.q-mb-md(
    //-     v-for="(termItem, index) in messages.terms_non_personal",
    //-     :key="index",
    //-     :class="{ 'text-bold': index.endsWith('bold') }"
    //-   ) {{ termItem }}

    //- .col-12.q-mt-lg
    //-   .text-h4.text-primary {{ $t('terms_d_level_of_security') }}
    //-   .text-body1.q-mt-lg.q-mb-md(
    //-     v-for="(termItem, index) in messages.terms_security",
    //-     :key="index",
    //-     :class="{ 'text-bold': index.endsWith('bold') }"
    //-   ) {{ termItem }}

    //- .col-12.q-mt-lg
    //-   .text-h4.text-primary {{ $t('terms_e_access_to_your_data') }}
    //-   .text-body1.q-mt-lg.q-mb-md(
    //-     v-for="(termItem, index) in messages.terms_data",
    //-     :key="index",
    //-     :class="{ 'text-bold': index.endsWith('bold') }"
    //-   ) {{ termItem }}

    //- .col-12.q-mt-lg
    //-   .text-h4.text-primary {{ $t('terms_f_privileges_and_immunities') }}
    //-   .text-body1.q-mt-lg.q-mb-md {{ $t('terms_f_privileges_and_immunities_1') }}

  //- .text-h3.text-center.q-mb-xl.q-mt-lg.text-primary {{ $t('event_privacy_policy') }}
  //-   .text-body1.q-mt-lg.q-mb-md 
  //-     a(
  //-       href="https://solferinoacademy.com/event-privacy-policy/",
  //-       target="_blank"
  //-     ) https://solferinoacademy.com/event-privacy-policy/

  //- .text-h3.text-center.q-mb-xl.q-mt-lg.text-primary {{ $t('community_guidelines') }}
  //-   .text-body1.q-mt-lg.q-mb-md 
  //-     a(
  //-       href="https://solferinoacademy.com/community-guidelines/",
  //-       target="_blank"
  //-     ) https://solferinoacademy.com/community-guidelines/
</template>

<script>
export default {
  computed: {
    messages() {
      return this.$i18n.messages[this.$i18n.locale];
    },
  },
};
</script>
